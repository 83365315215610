/* global FW_CONTEXT */
// @flow
import _ from 'underscore';
import {Backbone} from 'FWBackbone';
import {Store} from 'FW/Store';
import config from 'config';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import SiteLogo from '../FrontEnd/SiteLogo';
import isMobile from 'is-mobile';
import ErrorBoundary from '../ErrorBoundary';
import BannerAlert from '../Components/BannerAlert';
import {GrowlScene} from '@crystallize/react-growl';
const EditButton = React.lazy(() => import('../CommonElements/EditButton/EditButton'));
const FaithlifeGrowl = React.lazy(() => import('../CommonElements/FaithlifeGrowl/FaithlifeGrowl'));

let _isGlobalAdmin = false;
let _skPackage = 'better';
let _isSkCust = false;
let _isSKAdmin = false;
let _config = {};

/**
 * The main object for running Finalweb 2.0
 * @param {{}} config - Sets the configuration
 */
class FWCore {
  store: Store;
  Models: {};
  trigger: Function;

  constructor(c: {}) {
    _.extend(_config, c);
    _.extend(this, Backbone.Events);
    this.modal = {
      setModal: modal => {
        FW.editor.modal.setModal(modal);
      }
    };
    this.secondaryModal = {
      setModal: modal => {
        FW.editor.secondaryModal.setModal(modal);
      }
    };
  }

  /**
   * Returns a config value
   * @param key The key of the config value you want to retrieve
   * @returns {*}
   */
  config(key: string) {
    return _config[key];
  }

  bootUser() {
    FW.store.loaded('site', () => {
      let user = FW.store.get('user');
      $.post(config.API_ROOT + '/global-admin-check').then(data => {
        _isGlobalAdmin = data['global-admin'];
        FW.store.trigger('global-admin-changed');
      });

      $.post(config.API_ROOT + '/sk-admin-check').then(data => {
        _isSKAdmin = data['sk-admin'];
        _skPackage = data['sk-package'];
        _isSkCust = data['is-sk-cust'];
        FW.store.trigger('sk-admin-changed');
        FW.store.trigger('sk-package-changed');
        FW.store.trigger('sk-cust-changed');
      });

      // load the edit site button if the user is an editor
      if (!FW.inEditor() && user.isEditor() && FW_CONTEXT !== 'admin') {
        let el = document.createElement('div');
        if (document && document.body) {
          document.body.appendChild(el);
        }
        ReactDOM.render(<Suspense fallback={<div>Loading...</div>}>
          {FW.store.get('site').prop('faithlife_import') ? <FaithlifeGrowl /> : null}
          <EditButton />
        </Suspense>, el);
      }
    });
  }

  isWhiteLabel() {
    return (
      FW.store.get('reseller') &&
      (FW.store.get('reseller').get('reseller_white_label') ||
        FW.store.get('customer').get('reseller_white_label') !== null)
    );
  }

  bootResellerInfo() {
    let setupReseller = () => {
      let customer = FW.store.get('reseller') || FW.store.get('customer');
      if (customer.get('reseller_agreement')) {
        if (
          customer.get('reseller_logo_file_id') &&
          customer.get('reseller_logo')
        ) {
          config.PRODUCT_LOGO = customer
            .get('reseller_logo')
            .helper.featuredUrl();
          config.PRODUCT_LOGO_LARGE = customer
            .get('reseller_logo')
            .helper.featuredUrl();
        }
        if (customer.get('reseller_product_name')) {
          config.PRODUCT_NAME = customer.get('reseller_product_name');
        }
        if (customer.get('reseller_product_short_name')) {
          config.PRODUCT_SHORT_NAME = customer.get(
            'reseller_product_short_name'
          );
        }
      }
    };
    this.store.loaded('reseller', setupReseller);
    this.store.loaded('customer', setupReseller);
  }

  bootErrorReporting() {
    this.on('booted', () => {
      this.booted = true;
    });
  }

  skPackageAlert(message, contact = 'Call 800-773-7570 option 1') {
    swal.fire({
      title: 'Upgrade Required',
      html: (message ? '<span style=\'text-align: center; font-size: 16px\'>' + message + '</span><br /><br />' : '') + '<span style=\'text-align: center; font-size: 16px\'>Unlock this feature by upgrading your SK Sites package.<br />' +
        contact + '</span>',
      icon: 'warning'
    })
  }

  skPackageMatch(feature) {
    const packages = {
      best: ['*'],
      better: []
    }
    return packages[FW.skPackage()].indexOf(feature) >= 0 || packages[FW.skPackage()].indexOf('*') >= 0 || !FW.isSkCust();
  }

  isGlobalAdmin() {
    return _isGlobalAdmin;
  }

  skPackage() {
    return _skPackage;
  }

  isSkCust() {
    return _isSkCust;
  }

  isSKAdmin() {
    return _isSKAdmin;
  }

  bootParallax() {
    let animateItem = function() {
      $('.fw-parallax').each(function() {
        let s = $(window).scrollTop();
        let parent = $(this).parent();
        if (parent.inViewport()) {
          const groupElement = $(this).closest('[data-fw-model="PageGroup"]');
          const group = $(this).closest('[data-fw-model="PageGroup"]').group();
          //const backgroundFile = FW.Models.File.findOrCreate({id: group.prop('background_file_id')});
          let pHeight = parent.outerHeight();
          let wHeight;

          // this used to be here to fix an iOS bug. Then iOS was fixed, so I commented
          // this out.
          //if(Helper.isIOS()){
            //wHeight = window.screen.availHeight;
          //} else {
            wHeight = $(window).height();
          //}


          const imageOffset = group.ls('vertical_offset') || 0;
          const groupWrapperMargin = parseInt(groupElement.css('margin-top').replace('px', ''), 10);
          const parentMarginTop = parseInt(parent.css('margin-top').replace('px', ''), 10);
          // we will subtract the top margin from the offset because on some templates this messes up the first group.
          const groupElementOffset = groupElement.offset().top - groupWrapperMargin - parentMarginTop;
          let o = (groupElementOffset) + (imageOffset * (window.zoomScale || 1));
          const newImageHeight = pHeight * (group.ls('parallax_height') || 1.5);
          let height = pHeight < wHeight ? (newImageHeight > wHeight ? newImageHeight : wHeight) : pHeight;
          if ($(this).hasClass('fw-parallax')) {
            //const keepHeight = group.ls('keep_image_height');
            if ((height + "px") !== $(this).css('height')/* && !keepHeight*/) {
              $(this).css({
                height: height
              });
            }/* else if (keepHeight) {
              const imgHeight = parseInt(backgroundFile.get('details') ? backgroundFile.get('details').height : null, 10);
              const imgWidth = parseInt(backgroundFile.get('details') ? backgroundFile.get('details').width : null, 10);
              const aspectRatio = imgHeight / imgWidth;
              const requiredHeight = $(this).width() * aspectRatio;
              $(this).css({
                height: requiredHeight !== NaN ? requiredHeight : ''
              });
            }*/
            const diff = $(this).height() - pHeight;
            const ratio = (wHeight / diff) === Infinity ? 2 : wHeight / diff;
            let offset = ((s - o) / ratio).toFixed(0);
            if (window.zoomScale) {
              offset = offset / window.zoomScale;
            }
            $(this).css({transform: 'translate3d(0px, ' + offset + 'px, 0px)'});
          }
        }
      });
      requestAnimationFrame(animateItem);
    };
    requestAnimationFrame(animateItem);
  }

  getSlug() {
    let url = window.location.pathname;
    let slug = url.split('/')[1];
    return FW.store.get('FWController') || slug;
  }

  bootController() {
    let slug = this.getSlug();
    let boot = controller => {
      controller.default.boot();
    };
    console.log('SLUG: ', slug);
    if (slug === 'file') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */['Controllers/FileController.js'], boot);
    } else if (slug === 'blog') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/BlogController.js'], boot);
    } else if (slug === 'photo-gallery') {
      console.log('LOADING PHOTO GALLERY');
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/PhotoGalleryController.js'], boot);
    } else if (slug === 'membership-directory') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/MembershipDirectoryController.js'], boot);
    } else if (slug === 'series') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/SeriesController.js'], boot);
    } else if (slug === 'free-trial') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/TrialsController.js'], boot);
    } else if (slug === 'form') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/FormController.js'], boot);
    } else if (slug === 'stream-embed') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/StreamEmbedController.js'], boot);
    } else if (slug === 'cart') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/CartController.js'], boot);
    } else if (slug === 'order') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/OrderController.js'], boot);
    } else if (slug === 'ame-application-form') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/AmeController'], boot);
    } else if (slug === 'ame-reference-form') {
      // $FlowFixMe
      require(/* webpackPrefetch: true */ ['Controllers/AmeController'], boot);
    } else {
      // PageController.boot();
      // $FlowFixMe
      FW.store.loaded('FWController', function(e) {
        let obj = FW.store.get('FWController');
        if (obj === 'blog') {
          require(/* webpackPrefetch: true */ ['Controllers/BlogController.js'], boot);
        } else if (obj === 'page') {
          require(/* webpackPrefetch: true */ ['Controllers/PageController.js'], boot);
        } else {
          require(/* webpackPrefetch: true */ ['Controllers/Controller.js'], boot);
        }
      });
    }
  }

  mountBannerAlertIfNeeded() {
    FW.store.loaded('site', () => {
      if (FW.store.get('site').prop('banner_alert') && FW.store.get('site').prop('banner_alert').enable_banner) {
        let bannerMount = $('#banner-alert-container')[0];
        ReactDOM.render(<ErrorBoundary>
          <BannerAlert alert={FW.store.get('site').prop('banner_alert')} />
        </ErrorBoundary>, bannerMount);
      }
    });
  }

  bootPage() {
    this.store.loaded('JsonDataLoaded', () => {
      this.setupFooterLoad();
      FW.store.get('template').once('sectionsloaded', () => {
        console.log('CALLING BOOT CONTROLLER');
        this.bootController();
      });
      FW.store.get('template').loadTemplateInfo();
      FW.store.get('template').on('sectionsloaded', () => {
        this.mountFooterElement();
        this.mountBannerAlertIfNeeded();
      });
      this.bootParallax();
      FW.store.loaded('site', () => {
        this.setupTrialInfo();
        this.mountLogo();
      });
      this.setupCart();
    });
  }

  setupCart() {
    FW.store.loaded('cart_needed', function() {
      console.log('LOCATION: ', window.location);
      if (!FW.inEditor() && window.location.pathname !== '/cart') {
        import('Store/Cart/Cart').then(({Cart}) => {
          ReactDOM.render(<Cart />, document.getElementById('cart-mount'));
        });
      }
    });
  }

  setupFooterLoad() {
    this.on('booted', () => {
      if (FW.store.get('site').get('template_settings').setting('hideFooter')) {} else {
        setTimeout(() => {
          $('footer').show();
        }, 1);
      }
    });
  }

  setupTrialInfo() {
    try {
      let qs = new URLSearchParams(window.location.search);
      let justStarted = qs.get('just_started');
      if (
        FW.store
          .get('site')
          .get('customer')
          .get('status') === 'demo' &&
        isMobile() &&
        justStarted
      ) {
        swal.fire(
          'Site Created!',
          'Here\'s your demo site. If you\'d like to start editing, just click "Start Editing" at the bottom of the screen.',
          'success'
        );
      }

      let customer = FW.store.get('site').get('customer');

      if (
        customer.get('status') === 'demo' &&
        window.justStarted &&
        customer.get('is_demo_email')
      ) {
        import('Signup/MobileTrialForm').then((mod) => {
          $('body').append('<div id="trialSignup" />');
          let $el = $('#trialSignup');
          ReactDOM.render(<mod.default />, $el[0]);
        });
      }

      let startTrialButton = qs.get('startTrialButton');
      if (startTrialButton) {
        import('Signup/MobileStartTrialButton').then((mod) => {
          console.log('Mounting Trial Form');
          $('body').append('<div id="trialSignup" />');
          let $el = $('#trialSignup');
          ReactDOM.render(
            <mod.default blueprint={startTrialButton} />,
            $el[0]
          );
        });
      }
    } catch (e) {
      console.log('ERROR CAUGHT: ', e);
    }
  }

  mountLogo() {
    FW.store.loaded('pages', () => {
      let $logoMounts = $('.siteLogoMount');
      $logoMounts.each(i => {
        ReactDOM.render(<ErrorBoundary>
          <SiteLogo />
          <GrowlScene />
        </ErrorBoundary>, $logoMounts[i]);
      });
    });
  }

  mountFooterElement() {
    let group = FW.store.get('site').get('footer_group');
    if (group) {
      group.bootAsFooter();
    }
  }

  footerChanged(html) {
    FW.store.get('site').prop('footerContent', html);
    FW.store.get('site').save();
  }

  updateModel(className: string, attributes: {}) {
    FW.Models[className].findOrCreate(attributes);
  }

  startTrialSignup(blueprintGroupId = null, wels = false) {
    require(/* webpackPrefetch: true */ ['Signup/FreeTrialSignup.js'], form => {
      let FreeTrialSignup = form.default;
      let el = $('<div />').appendTo('body')[0];
      ReactDOM.render(<FreeTrialSignup logo={config.PRODUCT_LOGO_LARGE} blueprintGroupId={blueprintGroupId} wels={wels} />, el);
      $('body').addClass('no-scroll');
    });
  }

  previewTemplate(template, wels = false, hideChoose = false) {
    require(/* webpackPrefetch: true */ ['Signup/TemplatePreviewer'], comp => {
      let TemplatePreviewer = comp.default;
      if ($("#templatePreviewer").length === 0) {
        $('<div id="templatePreviewer" />').appendTo('body');
      }
      ReactDOM.render(<TemplatePreviewer hideChoose={hideChoose} preview={template ? true : false} wels={wels} template={template} onClose={() => {
        FW.previewTemplate(null)
      }} />, $("#templatePreviewer")[0]);
    });
  }

  saveTrial() {
    require(/* webpackPrefetch: true */ ['Signup/FreeTrialSignup.js'], form => {
      let FreeTrialSignup = form.default;
      let el = $('<div />').appendTo('body')[0];
      ReactDOM.render(
        <FreeTrialSignup
          saveTrial={true}
          onSaved={() => {
            ReactDOM.unmountComponentAtNode(el);
          }}
        />,
        el
      );

      $('body').addClass('no-scroll');
      FW.saving = true;
    });
  }

  getParent() {
    let myParent = window.parent;
    try {
      const context = myParent.FW_CONTEXT;
    } catch (e) {
      myParent = window.self;
    }
    if (typeof myParent.FW_CONTEXT === 'undefined') {
      myParent = window.self;
    }
    return myParent;
  }

  inEditor() {
    return FW.getParent().FW_CONTEXT === 'editor';
  }
}

//if (!conf.DEBUG_MODE) console.log = function() {};

export {FWCore};
